@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(246, 246, 249);
  color: #51545D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main{
  padding: calc(70px + 1.5rem) 1.5rem 1.5rem;
}

.calendar-disable::-webkit-inner-spin-button,
.calendar-disable::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -18px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 18px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: white;
  }

  50%,
  100% {
    background-color: #5c6bc0;
  }
}

.loading-state {
  cursor: none !important;
  pointer-events: none !important;
}

.modal-open {
  overflow: hidden !important;
}

.user-resource-content  iframe{
  width: 100% !important;
}